<template>
  <section>
    <main class="container">
      <IndexInfo isHome="1" class="card-border mb-48" v-if="sidebar.Index"/>
      <a
        href="https://collectify.app?utm_source=solananproject"
        class="adv"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <img src="@/assets/img/adv/logo.svg" height="36" class="logo"/>
          <p class="mt-12">The Essential Toolkit For NFT Creators, now support Solana!</p>
        </div>
        <img src="@/assets/img/adv/link.png" height="20" class="link"/>
      </a>
      <TVLInfo/>
      <TokenList isHome="1" class="card-border"/>
    </main>
  </section>
</template>

<script>
import TVLInfo from '@/components/TVLInfo'
import IndexInfo from '@/components/IndexInfo'
import TokenList from '@/components/TokenList'
export default {
  components:{TVLInfo,IndexInfo,TokenList},
}
</script>
<style scoped>
.adv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: url(../assets/img/adv/bg.png) no-repeat center/cover;
  margin-bottom: var(--px48);
  color: #fff;
  padding: 24px 24px 32px;
  border-radius: 4px;
  font-size: 20px;
  line-height: 1.25;
}
.mt-12 {
  margin-top: 16px;
}
.mb-48 {
  margin-bottom: var(--px48);
}
@media(max-width:768px){
  .adv{
    font-size: 16px;
    line-height: 22px;
    padding: 20px 16px 24px;
  }
  .adv .logo{
    height: 24px;
  }
  .adv .link{
    height: 16px;
  }
}
</style>
